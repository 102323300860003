<template>
  <div class="py-16 overflow-hidden lg:pt-16 lg:pb-40">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <img src="../../assets/img/logo-digitalizadores.png" alt="logo-digitalizadores">
      <p class="mt-4 mx-auto text-xl text-center leading-7 text-gray-900 mt-16">Bit Valencia es Agente Digitalizador adherido</p>
      <h3 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 my-12">
        Kit digital
      </h3>
      <p class="my-10 max-w-5xl mx-auto text-xl text-center leading-7 text-gray-900">¿En qué consiste el Kit Digital?</p>
      <div class="mt-4 mx-auto text-xl leading-7 text-gray-500 space-y-4">
        <p>La Unión Europea ha creado los Fondos Europeos ” Next  Generation EU “, con el objetivo de ayudar a afrontar la crisis económica causada por el Covid-19.</p>
        <p>El objetivo del programa es subvencionar una serie de servicios que ayuden a mejorar la competitividad de más de un millón de Pymes y autónomos.
        Subvencionando los servicios hasta el cien por cien durante los primeros 12 meses, únicamente pagando el IVA, ya que no está cubierto por la subvención.</p>
        <p>Dirigido a empresas de 1 a 49 empleados.</p>
      </div>
      <div class="mt-6 mx-auto text-xl leading-7 text-gray-500 space-y-4">
        <p>Si cumples con las condiciones establecidas en las bases de la convocatoria de la ayuda del Kit Digital, podrás disponer de un bono digital que te permitirá acceder a las soluciones de digitalización.</p>
        <p>El importe máximo se detalla en la tabla, y dependerá del tamaño de tu empresa:</p>
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider w-3/4">Segmentos de beneficiarios</th>
                    <th scope="col" class="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider w-1/4">Importe Bono Digital</th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-900">Segmento I. Pequeñas empresas de entre 10 y menos de 50 empleados</td>
                      <td class="px-6 py-4 whitespace-nowrap text-base text-gray-500">12.000 €</td>
                    </tr>
                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-900">Segmento II. Pequeñas empresas o Microempresas de entre 3 y menos de 10 empleados</td>
                      <td class="px-6 py-4 whitespace-nowrap text-base text-gray-500">6.000 €</td>
                    </tr>
                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-900">Segmento III. Pequeñas empresas o Microempresas de entre 1 y menos de 3 empleados y personas en situación de autoempleo</td>
                      <td class="px-6 py-4 whitespace-nowrap text-base text-gray-500">2.000 €</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <p>Lo primero que has de hacer es darte de alta como autónomo o Pyme y luego has de hacer una prueba de “diagnostico digital” que te dará una visión muy clara de las áreas donde debes mejorar. Con este informe podrás escoger las soluciones digitalizadoras que mejor se adapten a tu negocio.</p>
        <p>Aquí tienes el enlace para darte de alta y ampliar toda la información que necesites:</p>
        <p><a href="https://acelerapyme.es/kit-digital" target="_blank" class="text-indigo-600">Ampliar información</a></p>
      </div>
      <div class="mt-12 text-center">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div class="col-span-1 sm:col-span-2 text-center my-12">
            <h3 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Categorias del kit digital
            </h3>
          </div>
          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Sitio Web y presencia en internet</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Construye una página web y date visibilidad en internet. La imagen de una empresa es importante, y lo primero que verán tus potenciales clientes cuando investiguen es tu página web.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Páginas Wordpress autogestionables</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Son las adecuadas para páginas más sencillas y de implantación rápida, sin renunciar a la gestión de sus contenidos.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 1.200 a 3.800 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Páginas Premium autogestionables</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Son las adecuadas para páginas más complejas o que requieren funcionalidades específicas como pueden ser presupuestados online, reservas y otros que necesiten adaptarse a la empresa.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 2.000 a 6.500 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/sitio-web-y-presencia-en-internet">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Comercio electrónico</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Da visibiliad a tu marca mediante un e-commerce o tienda online. Una tienda online te permite vender hasta cuando estás durmiendo.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Comercio Woo-commerce</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Para tus páginas de Wordpress, totalmente autogestionables, productos, precios y descuentos, pasarela de pago.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 1.500 a 6.500 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Comercio Premium</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Para cualquier plataforma, totalmente autogestionable, con productos, precios y descuentos, stock, trazabilidad de pedidos, pasarela de pagos y facturación.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 2.000 a 9.800 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/comercio-electronico">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Gestión de redes sociales</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Promociona tu empresa en redes sociales. Las redes sociales son la forma de transmitir los valores de tu marca a tu público objetivo.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Gestión de producto en redes sociales</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Destaca un producto específico para su venta y potenciación del mismo en las redes sociales que elijas.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 1.200 a 2.500 € (IVA no incluido) durante un año</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Gestión de redes sociales</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Publicamos en hasta 5 redes sociales los contenidos acordados de forma que crezcan tanto el interés y seguidores como los contactos y ventas de tus clientes.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 2.000 a 6.500 € (IVA no incluido) durante un año</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/gestion-de-redes-sociales">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Gestión de clientes</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Digitaliza y optimiza las gestiones comerciales. Haz seguimiento a tus clientes con toda la información en el mismo lugar. Conexión con el correo para sincronización.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">CRM básico</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Registra tus leads y clientes, así como las conversaciones que realices con ellos. Cinco usuarios con acceso.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 1.800 € a 4.500 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">CRM Premium</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Registra tus leads y clientes, conversaciones, envío de propuestas, análisis de éxito, valoración de presupuestos perdidos. Cinco usuarios con acceso.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 2.500 € a 8.500 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/gestion-de-clientes">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Business intelligence y analítica</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Estudia los datos de tu empresa para mejorar las decisiones que tomas. Aprovecha la información de la que ya dispones para tomar decisiones acertadas.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">B.I. Básico</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Crea un cuadro de mandos con hasta 10 gráficos extraídos de tus aplicaciones mediante API o web services. 5 usuarios con acceso.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 1.800 € a 3.500 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">B.I. premium</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Crea un cuadro de mandos con hasta 20 gráficos extraídos de tus aplicaciones mediante API o web services. 5 usuarios con acceso.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 3.200 € a 6.600 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/business-intelligence-y-analitica">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Gestión de procesos</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Herramientas para el control de los procesos productivos para evitar fallos en la comunicación entre departamentos así como centralizar el control de la empresa en un mismo sistema.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">ERP Básico</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Control del procesos con las necesidades propias del proyecto a implantar. Proyectos básicos.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 2.000 € a 7.500 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">ERP premium</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Control del procesos con las necesidades propias del proyecto a implantar. Proyectos de complejidad media.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 6.000 € a 12.500 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/gestion-de-procesos">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Servicios y herramientas de oficina virtual</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Implantación de soluciones interactivas y funcionales que permitan la colaboración más eficiente entre los trabajadores, ya sea en el trabajo en la oficina como en remoto.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Microsoft 365 Mini (500 GB)</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Organiza un calendario con tus tareas y reuniones. Comparte y almacena archivos hasta 500 MB. Trabaja en colaboración en equipos de trabajo: comparte recursos y/o conocimientos, configuración y personalización de puestos de trabajo y tareas.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 140 a 160 €  (IVA no incluido) por usuario y año</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Microsoft 365 Empresa (1 TB)</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Organiza un calendario con tus tareas y reuniones. Comparte y almacena archivos hasta 1 TB. Trabaja en colaboración en equipos de trabajo: comparte recursos y/o conocimientos, configuración y personalización de puestos de trabajo y tareas.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 200 a 250 € (IVA no incluido) por usuario y año</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/servicios-y-herramientas-de-oficina-virtual">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                   </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Comunicaciones seguras</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Asegura las conexiones entre los dispositivos de tus empleados y la empresa. Controla y monitoriza quién tiene acceso a tus sistemas.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Securycom</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Implantación de comunicaciones seguras, con control de accesos y los de actividad. Actualización de sistemas y dispositivos para la seguridad de los mismos.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 50 a 170 € (IVA no incluido) por usuario y año dependiendo de las coberturas.</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/comunicaciones-seguras">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Ciberseguridad</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Proporciona seguridad básica y avanzada a tus empleados a través de software especializado en protección de acceso a claves e información.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Cybercescurity</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Antispyware, correo seguro, antispam, antiphising, navegación segura, monitorización de red y actualizaciones de seguridad.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 40 a 160 € (IVA no incluido) por usuario y año dependiendo de las coberturas.</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/ciberseguridad">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                  </svg>
                </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Factura electrónica</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Digitaliza y optimiza el flujo de emisión de facturas entre las empresas beneficiarias y sus clientes.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Factura electrónica básica</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Configura la factura con tu logotipo y colores corporativos. Permite la exportación e importación de facturas. Control de vencimiento de facturas y API para conexión con otros sistemas.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 3.600 € a 4.500 € (IVA no incluido), con instalación, 1 GB de almacenamiento  y 12 meses de mantenimiento</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Factura electrónica premium</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Configura la factura con tu logotipo y colores corporativos. Permite la exportación e importación de facturas. Control de vencimiento de facturas y API para conexión con otros sistemas.</p>
                <p class="mt-3 text-base text-gray-500 text-left">Además conecta la facturación con los sistemas de contabilidad como Sage, A3. Control de facturas pendientes, vencidas y pagadas.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 4.500 € a 7.500 € (IVA no incluido), con instalación, 2 GB de almacenamiento y 12 meses de mantenimiento.</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/factura-electronica">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Presencia avanzada en internet</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Prestación de funcionalidades y/o servicios que aseguren el posicionamiento del Beneficiario en internet, aumentando su alcance de potenciales clientes e incrementando el tráfico de visitas en su/-s plataforma/-s.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Posicionamiento 12 meses</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Posicionamiento inicial en internet, Análisis de palabras clave, Análisis de la competencia, SEO On-Page y Off-Page, Informes mensuales de posicionamiento.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 1.000 € a 4.500 € (IVA no incluido), con instalación  y 12 meses de mantenimiento y seguimiento.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Posicionamiento 24 meses</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Posicionamiento continuado en internet, Análisis de palabras clave, Análisis de la competencia, SEO On-Page y Off-Page, Informes mensuales de posicionamiento.</p>
                <p class="mt-1 text-base text-gray-500 text-left">De 2.000 € a 9.000 € (IVA no incluido), con instalación  y 24 meses de mantenimiento y seguimiento.</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/presencia-avanzada-internet">Más información</a></p>
              </div>
            </div>
          </div>

          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-medium text-gray-900 tracking-tight">Marketplace</h3>
                <p class="mt-5 text-base text-gray-500 text-left">Dirigir las referencias/productos de los beneficiarios para ayudar a la marca a incrementar su nivel de ventas, optimizando los recursos existentes, eligiendo los canales de distribución adecuados y adaptando las referencias/ productos a las necesidades externas.</p>
                <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Creación del contenido en un nuevo marketplace</h3>
                <p class="mt-3 text-base text-gray-500 text-left">Estudio previo de alternativas y alta en la plataforma, Análisis de la competencia, Diseño y definición de la estrategia de negocio, Producción del catálogo de referencias, Creación del contenido del listing y Alta de referencias en el marketplace. </p>
                <p class="mt-1 text-base text-gray-500 text-left">De 2.000 a 9.000 €  (IVA no incluido), con 12 meses de mantenimiento y seguimiento.</p>
                <p class="mt-5 text-indigo-600"><a href="/kit-digital/marketplace">Más información</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "DigitalSolutions",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
