<template>
  <div class="bg-gray-800">
    <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="xl:col-span-1">
          <img class="h-10" src="../../assets/img/logoWhite.png" alt="Company name">
          <p class="text-gray-300 text-base leading-6 mt-2">
            Bienvenido a la Innovación Tecnológica.
          </p>
          <div class="mt-8">
            <h3 class="text-lg leading-6 font-medium text-gray-400">
              Valencia
            </h3>
            <dl class="mt-2 text-base text-gray-400">
              <div>
                <dd>
                  Avenida Cortes Valencianas, 58, planta 5, 46015 Valencia
                </dd>
              </div>
              <div class="mt-1 text-gray-300">
                <dd>
                  Teléfono: <a href="tel:+34960451600" class="text-gray-300 hover:text-white">960 45 16 00</a>
                </dd>
              </div>
              <div class="mt-1 text-gray-300">
                <dd>
                  Correo: <a href="mailto:contact@bitvalencia.com" class="text-gray-300 hover:text-white">contact@bitvalencia.com</a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="mt-12 xl:mt-0 xl:col-span-2">
          <div class="md:grid md:grid-cols-3 md:gap-8">
            <div>
              <h4 class="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
                Plataformas ERP, CRM y SCM
              </h4>
              <ul class="mt-4 space-y-4">
                <li>
                  <a href="/plataformas#ventas-y-taller-motocicletas" class="text-base leading-6 text-gray-300 hover:text-white">
                    Ventas y taller motocicletas
                  </a>
                </li>
                <li>
                  <a href="/plataformas#gestion-explotaciones-agrarias" class="text-base leading-6 text-gray-300 hover:text-white">
                    Gestión explotaciones agrarias
                  </a>
                </li>
                <li>
                  <a href="/plataformas#gestion-inmobilarias" class="text-base leading-6 text-gray-300 hover:text-white">
                    Gestion inmobiliarias
                  </a>
                </li>
                <li>
                  <a href="/plataformas#control-de-produccion" class="text-base leading-6 text-gray-300 hover:text-white">
                    Control de producción
                  </a>
                </li>
                <li>
                  <a href="/plataformas#control-de-presencia-y-accesos" class="text-base leading-6 text-gray-300 hover:text-white">
                    Control de presencia y accesos
                  </a>
                </li>
                <li>
                  <a href="/plataformas#gestion-joyerias" class="text-base leading-6 text-gray-300 hover:text-white">
                    Gestión joyerias
                  </a>
                </li>
                <li>
                  <a href="/plataformas#gestion-de-clínicas-y-consultas" class="text-base leading-6 text-gray-300 hover:text-white">
                    Gestión de clínicas y consultas
                  </a>
                </li>
                <li>
                  <a href="/plataformas#gestion-hosteleria" class="text-base leading-6 text-gray-300 hover:text-white">
                    Gestión hostelería
                  </a>
                </li>
                <li>
                  <a href="/plataformas#gestion-de-clientes-y-comunicaciones" class="text-base leading-6 text-gray-300 hover:text-white">
                    Gestión de clientes y comunicaciones
                  </a>
                </li>
                <li>
                  <a href="/plataformas#gestion-de-supermercados" class="text-base leading-6 text-gray-300 hover:text-white">
                    Gestión de supermercados
                  </a>
                </li>
                <li>
                  <a href="/plataformas#gestion-de-alquiler-de-maquinaria" class="text-base leading-6 text-gray-300 hover:text-white">
                    Gestión de alquiler de maquinaria
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h4 class="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
                Aplicaciones móviles
              </h4>
              <ul class="mt-4 space-y-4">
                <li>
                  <a href="/aplicaciones-moviles#app-para-comerciales" class="text-base leading-6 text-gray-300 hover:text-white">
                    App para comerciales
                  </a>
                </li>
                <li>
                  <a href="/aplicaciones-moviles#app-movil-para-servicios-de-asistencia-tecnica" class="text-base leading-6 text-gray-300 hover:text-white">
                    App móvil para servicios de asistencia técnica
                  </a>
                </li>
                <li>
                  <a href="/aplicaciones-moviles#app-gestion-de-almacen-o-stock" class="text-base leading-6 text-gray-300 hover:text-white">
                    App gestión de almacén o stock
                  </a>
                </li>
                <li>
                  <a href="/aplicaciones-moviles#app-para-el-fichaje-de-los-trabajadores" class="text-base leading-6 text-gray-300 hover:text-white">
                    App para el fichaje de los trabajadores
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h4 class="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
                Blockchain, IOT y Hardware
              </h4>
              <ul class="mt-4 space-y-4">
                <li>
                  <a href="/blockchain#certificaciones-validadas" class="text-base leading-6 text-gray-300 hover:text-white">
                    Certificaciones validadas
                  </a>
                </li>
                <li>
                  <a href="/blockchain#contratos-automaticos" class="text-base leading-6 text-gray-300 hover:text-white">
                    Contratos automáticos
                  </a>
                </li>
                <li>
                  <a href="/blockchain#facturacion-auditada" class="text-base leading-6 text-gray-300 hover:text-white">
                    Facturación auditada
                  </a>
                </li>
                <li>
                  <a href="/blockchain#trazabilidad-incorruptible" class="text-base leading-6 text-gray-300 hover:text-white">
                    Trazabilidad incorruptible
                  </a>
                </li>
                <li>
                  <a href="/blockchain#iot-y-sensores" class="text-base leading-6 text-gray-300 hover:text-white">
                    IOT y sensores
                  </a>
                </li>
                <li>
                  <a href="/blockchain#hardware" class="text-base leading-6 text-gray-300 hover:text-white">
                    Hardware
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t border-gray-200 pt-8">
        <p class="text-base leading-6 text-white xl:text-center">
          &copy; {{ currentYear }} El prestador de servicios de la página web https://bitvalencia.es es BLOCKCHAIN STACK SL, con domicilio social en Calle Juan de Juanes 22, provincia de VALENCIA, CIF nº B40611824. La empresa consta inscrita en el Registro Mercantil de de Valencia, en el T 10725, L 8005, F 129, S 8, H V 189539, I/A 1, con fecha 26.09.19
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
