<template>
  <div class="py-16 overflow-hidden lg:pt-16 lg:pb-40">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <div class="flex items-center justify-start ml-4 mb-4">
        <a href="/kit-digital" class="flex space-x-2">
          <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
          <div class="text-xl">Volver</div>
        </a>
      </div>
      <img src="../../assets/img/logo-digitalizadores.png" alt="logo-digitalizadores">
      <h3 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 my-16">
        Servicios y herramientas de oficina virtual
      </h3>
      <div class="mx-auto text-center text-xl leading-7 text-gray-500">
        <p class="mt-5 text-base text-gray-500 text-left">Implantación de soluciones interactivas y funcionales que permitan la colaboración más eficiente entre los trabajadores, ya sea en el trabajo en la oficina como en remoto.</p>
        <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Microsoft 365 Mini (500 GB)</h3>
        <p class="mt-3 text-base text-gray-500 text-left">Organiza un calendario con tus tareas y reuniones. Comparte y almacena archivos hasta 500 MB. Trabaja en colaboración en equipos de trabajo: comparte recursos y/o conocimientos, configuración y personalización de puestos de trabajo y tareas.</p>
        <p class="mt-1 text-base text-gray-500 text-left">De 140 a 160 €  (IVA no incluido) por usuario y año</p>
        <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Microsoft 365 Empresa (1 TB)</h3>
        <p class="mt-3 text-base text-gray-500 text-left">Organiza un calendario con tus tareas y reuniones. Comparte y almacena archivos hasta 1 TB. Trabaja en colaboración en equipos de trabajo: comparte recursos y/o conocimientos, configuración y personalización de puestos de trabajo y tareas.</p>
        <p class="mt-1 text-base text-gray-500 text-left">De 200 a 250 € (IVA no incluido) por usuario y año</p>
      </div>
      <dl class="mt-12 lg:mt-16 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:gap-x-10">
        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Colaboración en equipos de trabajo</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución permitirá una gestión ágil y
            eficiente de los equipos para el desarrollo de proyectos de forma colaborativa, compartir
            recursos y/o conocimientos, dotar de herramientas para la interacción de los equipos en
            los procesos de ideación o resolución de problemas, así como la configuración y
            personalización de flujos de trabajo, etc.

          </dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Almacenar y compartir archivos</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá proveer 1 TB de almacenamiento.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Compatibilidad con dispositivos móviles</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500"></dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Calendario y agenda</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá permitir organizar un calendario y las tareas
            previstas.
          </dd>
        </div>
      </dl>
      <div class="flex flex-col justify-center items-center space-y-10 mt-12 lg:mt-24">
        <div class="text-center text-3xl leading-8 font-extrabold">¿QUIERES SABER CÓMO BENEFICIARTE DE ESTOS SERVICIOS?</div>
        <a href="/#contacto" class="flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
          Más información
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Website",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>