<template>
  <div class="py-16 overflow-hidden lg:pt-16 lg:pb-40">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <div class="flex items-center justify-start ml-4 mb-4">
        <a href="/kit-digital" class="flex space-x-2">
          <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
          <div class="text-xl">Volver</div>
        </a>
      </div>
      <img src="../../assets/img/logo-digitalizadores.png" alt="logo-digitalizadores">
      <h3 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 my-16">
        Factura electrónica
      </h3>
      <div class="mx-auto text-center text-xl leading-7 text-gray-500">
        <p class="mt-5 text-base text-gray-500 text-left">Digitaliza y optimiza el flujo de emisión de facturas entre las empresas beneficiarias y sus clientes.</p>
        <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Factura electrónica básica</h3>
        <p class="mt-3 text-base text-gray-500 text-left">Configura la factura con tu logotipo y colores corporativos. Permite la exportación e importación de facturas. Control de vencimiento de facturas y API para conexión con otros sistemas.</p>
        <p class="mt-1 text-base text-gray-500 text-left">De 3.600 € a 4.500 € (IVA no incluido), con instalación, 1 GB de almacenamiento  y 12 meses de mantenimiento</p>
        <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Factura electrónica premium</h3>
        <p class="mt-3 text-base text-gray-500 text-left">Configura la factura con tu logotipo y colores corporativos. Permite la exportación e importación de facturas. Control de vencimiento de facturas y API para conexión con otros sistemas.</p>
        <p class="mt-3 text-base text-gray-500 text-left">Además conecta la facturación con los sistemas de contabilidad como Sage, A3. Control de facturas pendientes, vencidas y pagadas.</p>
        <p class="mt-1 text-base text-gray-500 text-left">De 4.500 € a 7.500 € (IVA no incluido), con instalación, 2 GB de almacenamiento y 12 meses de mantenimiento.</p>
      </div>
      <dl class="mt-12 lg:mt-16 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:gap-x-10">
        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Facturas en formato estructurado</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá permitir la emisión de facturas en formatos estructurados, al menos en formato FACTURA-E, para facilitar su tratamiento automatizado. </dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Facturas ilimitadas</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá permitir la emisión y recepción de un número ilimitado de facturas.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Clientes ilimitados</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá permitir el envío de facturas a un número ilimitado de clientes.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Productos o servicios ilimitados</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá permitir la creación de un número ilimitado de productos y/o servicios facturables dentro del catálogo.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Envío y recepción de facturas</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá ser capaz de enviar y recibir facturas electrónicas directamente, al menos por correo electrónico.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Personalización de facturas</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">Las facturas generadas por la solución deberán ser personalizables, incluyendo la selección del logotipo.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Copia de seguridad periódicas</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución incluirá la realización de copias de seguridad periódicas con posibilidad, al menos, de periodicidad diaria.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">1 GB Almacenamiento/Histórico de facturas</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución proporcionará un almacenamiento de al menos 1 GB para las facturas.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Integración con otras soluciones</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá disponer de APIs o Web Services para su integración con otras herramientas, así como permitir la carga de datos de manera manual.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Control de vencimiento de las facturas</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá incluir un sistema de control del vencimiento de las facturas.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Generación de un registro de facturación</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">De forma simultánea o inmediatamente anterior a la expedición de cada factura, en un formato estandarizado y garantizando la integridad, conservación, accesibilidad, legibilidad, trazabilidad, e inalterabilidad de los datos que componen dicho registro de facturación.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Emisión de facturas verificables</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá emitir facturas, tanto en papel como en soporte electrónico, que incluyan los elementos necesarios para su verificación contra la Agencia Tributaria por parte del comprador.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Verificación presencial</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá permitir la verificación presencial del registro de facturación por parte de las autoridades competentes.</dd>
        </div>
      </dl>
      <div class="flex flex-col justify-center items-center space-y-10 mt-12 lg:mt-24">
        <div class="text-center text-3xl leading-8 font-extrabold">¿QUIERES SABER CÓMO BENEFICIARTE DE ESTOS SERVICIOS?</div>
        <a href="/#contacto" class="flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
          Más información
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ElectronicInvoice",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
