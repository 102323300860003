<template>
  <div class="py-16 overflow-hidden lg:pt-16 lg:pb-40">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <div class="flex items-center justify-start ml-4 mb-4">
        <a href="/kit-digital" class="flex space-x-2">
          <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
          <div class="text-xl">Volver</div>
        </a>
      </div>
      <img src="../../assets/img/logo-digitalizadores.png" alt="logo-digitalizadores">
      <h3 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 my-16">
        Marketplace
      </h3>
      <div class="mx-auto text-center text-xl leading-7 text-gray-500">
        <p class="mt-5 text-base text-gray-500 text-left">Dirigir las referencias/productos de los beneficiarios para ayudar a la marca a incrementar su nivel de ventas, optimizando los recursos existentes, eligiendo los canales de distribución adecuados y adaptando las referencias/ productos a las necesidades externas.</p>
        <h3 class="mt-5 text-base font-medium text-gray-900 text-left">Creación del contenido en un nuevo marketplace</h3>
        <p class="mt-3 text-base text-gray-500 text-left">Estudio previo de alternativas y alta en la plataforma, Análisis de la competencia, Diseño y definición de la estrategia de negocio, Producción del catálogo de referencias, Creación del contenido del listing y Alta de referencias en el marketplace. </p>
        <p class="mt-1 text-base text-gray-500 text-left">De 2.000 a 9.000 €  (IVA no incluido), con 12 meses de mantenimiento y seguimiento.</p>
      </div>
      <dl class="mt-12 lg:mt-16 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:gap-x-10">
        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Estudio previo de alternativas y alta en la plataforma</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">Apertura de cuenta y alta del perfil del Beneficiario, por su cuenta, en, al menos, una (1) plataforma de Marketplace y en, al menos, un (1) país.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Análisis de la competencia</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">Realización de una investigación de mercado focalizada en las características de los competidores para mejorar el proceso de toma de decisiones y alcanzar una posición competitiva.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Diseño y definición de la estrategia de negocio</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">Generación de la estrategia de negocio para lograr la consecución de los objetivos deseados orientando los recursos disponibles hacia dicha finalidad.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Producción del catálogo de referencias</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">Análisis de las categorías a comercializar para seleccionar, al menos, diez (10) productos alineados con la estrategia de negocio, salvo que el Beneficiario no disponga de este número, en cuyo caso podrá ser menor.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Creación del contenido del listing</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">Definición de, al menos, diez (10) descripciones del catálogo de referencias, salvo que el Beneficiario no disponga de este número, en cuyo caso podrá ser menor.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Alta de referencias</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">Carga de, al menos, diez (10) referencias, y sus respectivas descripciones y fotografías, en la plataforma, salvo que el Beneficiario no disponga de este número, en cuyo caso podrá ser menor, acompañada por la descripción y fotografía individual de cada producto.</dd>
        </div>
      </dl>
      <div class="flex flex-col justify-center items-center space-y-10 mt-12 lg:mt-24">
        <div class="text-center text-3xl leading-8 font-extrabold">¿QUIERES SABER CÓMO BENEFICIARTE DE ESTOS SERVICIOS?</div>
        <a href="/#contacto" class="flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
          Más información
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Marketplace",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
