<template>
  <div class="py-16 overflow-hidden lg:pt-16 lg:pb-40">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <div class="flex items-center justify-start ml-4 mb-4">
        <a href="/kit-digital" class="flex space-x-2">
          <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
          <div class="text-xl">Volver</div>
        </a>
      </div>
      <img src="../../assets/img/logo-digitalizadores.png" alt="logo-digitalizadores">
      <h3 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 my-16">
        Gestión de procesos
      </h3>
      <div class="mx-auto text-center text-xl leading-7 text-gray-500">
        <p class="mt-5 text-base text-gray-500 text-left">Herramientas para el control de los procesos productivos para evitar fallos en la comunicación entre departamentos así como centralizar el control de la empresa en un mismo sistema.</p>
        <h3 class="mt-5 text-base font-medium text-gray-900 text-left">ERP Básico</h3>
        <p class="mt-3 text-base text-gray-500 text-left">Control del procesos con presupuestos, pedidos, estados de producción e incidencias, albaranes de entrega y facturación.</p>
        <p class="mt-1 text-base text-gray-500 text-left">De 2.000 € a 7.500 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
        <h3 class="mt-5 text-base font-medium text-gray-900 text-left">ERP premium</h3>
        <p class="mt-3 text-base text-gray-500 text-left">Control del procesos con gestión comercial, presupuestos, pedidos, estados de producción e incidencias, albaranes de entrega, facturación, contabilidad y conexión a programas externos de contabilidad.</p>
        <p class="mt-1 text-base text-gray-500 text-left">De 6.000 € a 12.500 € (IVA no incluido), con instalación y 12 meses de mantenimiento</p>
      </div>
      <dl class="mt-12 lg:mt-16 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:gap-x-10">
        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Digitalización y/o automatización de procesos y flujos de trabajo</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">la solución deberá permitir la digitalización y/o automatización de procesos tales como:
            <ul>
              <li>- Contabilidad/finanzas: cuentas por cobrar/por pagar, gestión de activos y
                generación de cierres y balances, etc.
              </li>
              <li>- Facturación: automatización de los procesos de facturación con la generación de
                presupuestos, albaranes y facturas.
              </li>
              <li>- Proyectos: control de presupuestos, costes, estimaciones, optimización de los
                recursos, etc.
              </li>
              <li>- Inventario: previsión, niveles de stock, envíos, distribuciones, devoluciones y
                cancelaciones, etc.
              </li>
              <li>- Recursos humanos: gestión de los recursos humanos, nóminas, etc.</li>
              <li>- Logística: gestión de flotas y rutas, entre otras.</li>
            </ul>
          </dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Integración con diversas plataformas</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá disponer de APIs o Web Services para su integración con otras herramientas.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Actualizable</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá ser actualizable con nuevas versiones.</dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Escalable</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución deberá poder adaptarse a los posibles crecimientos o
            cambios en la estructura empresarial de la pyme.
          </dd>
        </div>

        <div class="relative">
          <dt>
            <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Cumplimiento</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">La solución debe permitir que el beneficiario pueda asegurar el
            cumplimiento del Reglamento por el que se regulan las obligaciones de facturación del
            Real Decreto 1619/2012, así como cualquier normativa de aplicación.
          </dd>
        </div>
      </dl>
      <div class="flex flex-col justify-center items-center space-y-10 mt-12 lg:mt-24">
        <div class="text-center text-3xl leading-8 font-extrabold">¿QUIERES SABER CÓMO BENEFICIARTE DE ESTOS SERVICIOS?</div>
        <a href="/#contacto" class="flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
          Más información
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Website",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>