import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Platforms from '../views/Platforms.vue'
import Applications from '../views/Applications.vue'
import Blockchain from '../views/Blockchain.vue'
import DigitalKit from '../views/DigitalKit/index'
import Website from '../views/DigitalKit/Website'
import Commerce from '../views/DigitalKit/Commerce'
import SocialNetworks from '../views/DigitalKit/SocialNetworks'
import Customers from '../views/DigitalKit/Customers'
import Analytics from '../views/DigitalKit/Analytics'
import Communications from '../views/DigitalKit/Communications'
import Cybersecurity from "../views/DigitalKit/Cybersecurity";
import Processes from "../views/DigitalKit/Processes";
import OfficeTools from "../views/DigitalKit/OfficeTools";
import ElectronicInvoice from "../views/DigitalKit/ElectronicInvoice";
import AdvancedInternetPresence from "@/views/DigitalKit/AdvancedInternetPresence";
import Marketplace from "@/views/DigitalKit/Marketplace";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/plataformas',
    name: 'Platforms',
    component: Platforms
  },
  {
    path: '/aplicaciones-moviles',
    name: 'ApplicationsMobile',
    component: Applications
  },
  {
    path: '/blockchain',
    name: 'Blockchain',
    component: Blockchain
  },
  {
    path: '/kit-digital',
    name: 'Digital-kit',
    component: DigitalKit
  },
  {
    path: '/kit-digital/sitio-web-y-presencia-en-internet',
    name: 'Website',
    component: Website
  },
  {
    path: '/kit-digital/comercio-electronico',
    name: 'Commerce',
    component: Commerce
  },
  {
    path: '/kit-digital/gestion-de-redes-sociales',
    name: 'SocialNetworks',
    component: SocialNetworks
  },
  {
    path: '/kit-digital/gestion-de-clientes',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/kit-digital/business-intelligence-y-analitica',
    name: 'Customers',
    component: Analytics
  },
  {
    path: '/kit-digital/gestion-de-procesos',
    name: 'Processes',
    component: Processes
  },
  {
    path: '/kit-digital/servicios-y-herramientas-de-oficina-virtual',
    name: 'OfficeTools',
    component: OfficeTools
  },
  {
    path: '/kit-digital/comunicaciones-seguras',
    name: 'Communications',
    component: Communications
  },
  {
    path: '/kit-digital/ciberseguridad',
    name: 'Cybersecurity',
    component: Cybersecurity
  },
  {
    path: '/kit-digital/factura-electronica',
    name: 'ElectronicInvoice',
    component: ElectronicInvoice
  },
  {
    path: '/kit-digital/presencia-avanzada-internet',
    name: 'AdvancedInternetPresence',
    component: AdvancedInternetPresence
  },
  {
    path: '/kit-digital/marketplace',
    name: 'Marketplace',
    component: Marketplace
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
