<template>
  <div id="app" class="font-base">
    <div id="nav">
      <Navigation></Navigation>
    </div>
    <div id="content">
      <router-view />
    </div>
    <div id="foot">
      <Footer></Footer>
    </div>
  </div>
</template>


<script>
import Navigation from "@/views/layout/Navigation";
import Footer from "@/views/layout/Footer";
export default {
  components: {
    Navigation,
    Footer
  }
}
</script>